import React, { Component } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { createMedia } from '@artsy/fresnel'
import { useLocation } from '@reach/router'
import {
  Button,
  Container,
  Icon,
  Menu,
  Segment,
  Sidebar,
  Visibility,
  Header
} from 'semantic-ui-react'
import Footer from './Footer'
import './all.scss'
import useSiteMetadata from './SiteMetadata'

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024
  }
})

const Routes = [
  {
    name: 'Home',
    path: '/'
  },
  {
    name: 'Sign Up',
    path: '/shop'
  },
  {
    name: 'Create',
    path: '/private'
  }
]

const MenuRoutes = () => {
  const { pathname } = useLocation()
  return Routes.map(route =>
    route.path === pathname ? (
      <Menu.Item key={route.name} as={Link} to={route.path} active>
        {route.name}
      </Menu.Item>
    ) : (
      <Menu.Item key={route.name} as={Link} to={route.path}>
        {route.name}
      </Menu.Item>
    )
  )
}

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children, block } = this.props
    const { fixed } = this.state
    const { image = {} } = block || {}

    return (
      <Media greaterThan="mobile">
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign="center"
            style={{
              minHeight: block ? 700 : 'unset',
              padding: block ? '1em 0em' : '1em 0 0 0',
              backgroundImage: `url(${
                !!image.childImageSharp ? image.childImageSharp.fluid.src : image
              })`
            }}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size="large"
            >
              <Container>
                <MenuRoutes />
                <Menu.Item position="right">
                  <Button as="a" inverted={!fixed}>
                    Log in
                  </Button>
                  <Button as="a" inverted={!fixed} primary={fixed} style={{ marginLeft: '0.5em' }}>
                    Sign Up
                  </Button>
                </Menu.Item>
              </Container>
            </Menu>
            {block && <HomepageHeading block={block} />}
          </Segment>
        </Visibility>
        <div style={{ minHeight: 'calc(100vh - 357px)' }}>{children}</div>
        <Footer />
      </Media>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
  block: PropTypes.any
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children, block } = this.props
    const { sidebarOpened } = this.state

    return (
      <Media as={Sidebar.Pushable} at="mobile">
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation="overlay"
            inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
          >
            <Menu.Item as="a" active>
              Home
            </Menu.Item>
            <Menu.Item as={Link} to="/shop">
              Sign Up
            </Menu.Item>
            <Menu.Item as={Link} to="/contact">
              Contact
            </Menu.Item>
            <Menu.Item as={Link} to="/about">
              About
            </Menu.Item>
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign="center"
              style={{ minHeight: 350, padding: '1em 0em' }}
              vertical
            >
              <Container>
                <Menu inverted pointing secondary size="large">
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name="sidebar" />
                  </Menu.Item>
                  <Menu.Item position="right">
                    <Button as="a" inverted>
                      Log in
                    </Button>
                    <Button as="a" inverted style={{ marginLeft: '0.5em' }}>
                      Sign Up
                    </Button>
                  </Menu.Item>
                </Menu>
              </Container>
              {block && <HomepageHeading block={block} mobile />}
            </Segment>
            {children}
            <Footer />
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
  block: PropTypes.any
}

/* Heads up!
 * HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled
 * components for such things.
 */
const HomepageHeading = ({ mobile, block: { title, heading } = {} }) => (
  <Container text>
    <Header
      as="h1"
      content={title}
      inverted
      style={{
        fontSize: mobile ? '2em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '3em'
      }}
    />
    <Header
      as="h2"
      content={heading}
      inverted
      style={{
        fontSize: mobile ? '1.5em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1.5em'
      }}
    />
    <Button primary size="huge">
      Get Started
      <Icon name="right arrow" />
    </Button>
  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
  block: PropTypes.any
}

const ResponsiveContainer = ({ children, block }) => {
  const { title, description } = useSiteMetadata()
  return (
    /* Heads up!
     * For large applications it may not be best option to put all page into these containers at
     * they will be rendered twice for SSR.
     */
    <MediaContextProvider>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="apple-touch-icon" sizes="180x180" href="./img/apple-touch-icon.png" />
        <link rel="icon" type="image/png" href="/img/favicon.png" />
        <link rel="mask-icon" href="/img/safari-pinned-tab.svg" color="#ff4400" />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/og-image.jpg" />
      </Helmet>
      <DesktopContainer block={block}>{children}</DesktopContainer>
      <MobileContainer block={block}>{children}</MobileContainer>
    </MediaContextProvider>
  )
}

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
  block: PropTypes.any
}

export default ResponsiveContainer
