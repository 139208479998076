import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import { Header, List } from 'semantic-ui-react'

function Pages(props) {
  const { data } = props
  const { edges: pages } = data.allMarkdownRemark

  return (
    <>
      <Header inverted as="h4" content="Pages" />
      <List link inverted>
        {pages &&
          pages.map(page => (
            <List.Item key={page?.node?.fields?.slug} as={Link} to={page?.node?.fields?.slug}>
              {page?.node?.frontmatter?.label}
            </List.Item>
          ))}
        {props.children}
      </List>
    </>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query PageListQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___title] }
          filter: { frontmatter: { templateKey: { eq: "simple-page" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                label
                templateKey
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <Pages data={data} count={count} />}
  />
)
