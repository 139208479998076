import React from 'react'
import { Link } from 'gatsby'
import { Container, Grid, Header, List, Segment, Button, Image } from 'semantic-ui-react'
import PageList from './PageList'

function Footer() {
  return (
    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <PageList>
                <List.Item as={Link} to="/openings">
                  Meals
                </List.Item>
              </PageList>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as="h4" content="Classes" />
              <List link inverted>
                <List.Item as={Link} to="/shop">
                  Sign Up
                </List.Item>
                <List.Item as={Link} to="/menu/home">
                  Recipe Cards
                </List.Item>
                <List.Item as={Link} to="/meals">
                  Meals
                </List.Item>
                <List.Item as={Link} to="/private">
                  Request a Private Class
                </List.Item>
                <List.Item as={Link} to="/release">
                  Next Release Day
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Image src="img/citrus-wh.svg" alt="Citrus Pear" style={{maxWidth: 150}} />
              <Button primary as={Link} to="/shop">
                Join Us
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  )
}

export default Footer
